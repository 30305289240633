.detail-image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 250px;
    margin-top: -70px !important;
    padding: 22px 0px;
    background-size: cover;
    background-position: top center;
}

.detail-image p {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background-color: #000000D9;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
}

.detail-image p b {
    font-weight: 700;
    color: #FF5471;
}

#detailPage {
    margin-top: 20px !important;
    padding-bottom: 10rem;
}

.detail-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 16px;
    padding-top: 0px;
    border-bottom: 1px solid #F4F4F5;
}

.detail-info .category {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #ED503C;
}

.detail-info h6 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #000000D9;
    font-weight: 700;
}

.detail-info .bottom-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.detail-info .star-point {
    font-weight: bold;
}

.detail-info .detail-price {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0px 8px;
}

.detail-info .detail-price b {
    font-size: 18px;
    font-weight: 700;
    color: #000000D9;
}

.detail-info .detail-price p {
    font-size: 12px;
    font-weight: 400;
    color: #00000073;
}

.detail-intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid #F4F4F5;
}

.detail-intro p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}

#detailPage .detail-floating {
    display: flex;
    gap: 0px 8px;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    padding: 10px 16px 10px;
    width: 400px;
    height: auto;
    box-sizing: border-box;
    background-color: transparent;
}

.detail-skill {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 16px;
    gap: 6px 0px;
    border-bottom: 1px solid #F4F4F5;
}

.detail-skill .skill {
    display: flex;
    align-items: center;
    gap: 0px 20px;
}

.detail-skill .tag-badge {
    display: flex;
    align-items: center;
    gap: 0px 4px;
}

.detail-skill .tag-badge span {
    padding: 6px 7px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    font-size: 12px;
    font-weight: 400;
    background-color: #F2F3F3;
    color: #000000D9;
}

#detailPage .detail-floating button {
    height: 48px;
    background-color: #ED503C;
    font-weight: 500;
}