.card {
    position: relative;
    width: 600px;
    padding: 20px;
    max-width: 85vw;
    height: 50vh;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.card > h3 {
    position: absolute;
    bottom: 10px; 
    color: white;
}
.tinderCards__cardContainer {
    /* margin-top: 5vh; */
}

.swipe {
    position: absolute;
}